import * as React from "react"
import { Link } from "gatsby"

import PageHeader from "../components/pageheader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/404.module.css"
import hero from "../images/404-hero.jpg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageHeader
      image={hero}
      breadcrumbs={[
        { label: "Error 404", link: null },
      ]}
    >
      <div className={`mx-auto py-32 ${styles.title404Area}`}>
        <h1 className="font-bold font-serif text-center text-7xl mb-4">Error 404</h1>
        <p className="text-center text-xl">
          Oops sorry this page doesn't exist
        </p>
        <div className="text-center mt-4">
          <Link to="/" className="text-xl font-bold">
            Back to Home
          </Link>
        </div>
      </div>
    </PageHeader>
  </Layout>
)

export default NotFoundPage
